.btn {
	&.btn-icon {
		width: rem(28px);
		height: rem(28px);
		padding: 0;
		border: none;
		line-height: rem(28px);
		text-align: center;
		font-size: rem(14px);
		border-radius: 28px;
		
		&.btn-xs {
			width: rem(16px);
			height: rem(16px);
			font-size: rem(8px);
			line-height: rem(16px);
		}
		&.btn-sm {
			width: rem(22px);
			height: rem(22px);
			font-size: rem(11px);
			line-height: rem(22px);
		}
		&.btn-lg {
			width: rem(34px);
			height: rem(34px);
			font-size: rem(17px);
			line-height: rem(34px);
		}
	}
	&.btn-xs {
		font-size: $font-size-sm;
		padding: rem(2px) rem(6px);
	}
	&[class*="btn-outline-"]:not(.btn-yellow):not(.btn-white) {
		&:hover {
			color: $white;
		}
	}
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
		$bg:                 $value;
		$border:             $value;
		$textColor:          $white;
		$hoverBg:            shade-color($value, 25%);
		$hoverBorder:        shade-color($value, 25%);
		$hoverTextColor:     $white;
		$activeBg:           shade-color($value, 25%);
		$activeBorder:       shade-color($value, 25%);
		$activeTextColor:    $white;
		$disabledBg:         tint-color($value, 25%);
		$disabledBorder:     tint-color($value, 25%);
		$disabledTextColor:  $white;
		
		@if $color == 'yellow' or $color == 'default' or $color == 'white' {
			$textColor: $gray-900;
			$hoverTextColor: $gray-900;
			$activeTextColor: $gray-900;
			$disabledTextColor: $gray-900;
		}
		@if $color == 'default' {
			$hoverBg: $gray-400;
			$hoverBorder: $gray-400;
			$activeBg: $gray-400;
			$activeBorder: $gray-400;
		}
		@if $color == 'white' {
			$border: $gray-400;
			$hoverBg: $gray-200;
			$hoverBorder: $gray-500;
			$activeBg: $gray-200;
			$activeBorder: $gray-500;
			$disabledBg: tint-color($gray-200, 25%);
			$disabledBorder: tint-color($gray-500, 25%);
		}
		
		@include button-variant(
			$bg, $border, $textColor, $hoverBg, 
			$hoverBorder, $hoverTextColor, 
			$activeBg, $activeBorder, $activeTextColor,  
			$disabledBg, $disabledBorder, $disabledTextColor
		);
		
		.btn-group > .btn + &,
		.input-group > .btn + &,
		.input-group > .btn + .dropdown-menu + & {
			@if $enable-rtl {
				border-right: 1px solid $hoverBorder;
			} @else {
				border-left: 1px solid $hoverBorder;
			}
		}
  }
}