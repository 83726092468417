.Modal {
  display: flex; 
  overflow: hidden; 
  background-color: rgb(255, 255, 255); 
  outline: none; 
  min-width: 32em; 
  padding: 1.25rem; 
  margin: auto; 
  border-radius: 0.3125em; 
  text-align: center; 
  position: relative; 
  flex-direction: column; 
  justify-content: center; 
  max-width: 100%; 
}

.Overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.OverlayAddTop {
  z-index: 9999999;
}

// modal
.icon-info-h {
  width: 80px; height: 80px; border-width: 4px; border-style: solid; border-color: rgb(70, 184, 218); border-radius: 50%; margin: 20px auto; position: relative; box-sizing: content-box;

  .info-r1{
    position: absolute; width: 5px; height: 29px; left: 50%; bottom: 17px; border-radius: 2px; margin-left: -2px; background-color: rgb(91, 192, 222);
  }
  .info-r2{
    position: absolute; width: 7px; height: 7px; border-radius: 50%; margin-left: -3px; left: 50%; top: 19px; background-color: rgb(91, 192, 222);
  }
}

.icon-success-h {
  width: 80px; height: 80px; border-width: 4px; border-style: solid; border-color: rgb(76, 174, 76); border-radius: 50%; margin: 20px auto; position: relative; box-sizing: content-box;

  .success-r1 {
    border-radius: 120px 0px 0px 120px; position: absolute; width: 60px; height: 100px; background: white; transform: rotate(-45deg); top: -7px; left: -33px; transform-origin: 60px 60px;
  }

  .success-r1-sp1 {
    height: 5px; background-color: rgb(92, 184, 92); display: block; border-radius: 2px; position: absolute; z-index: 2; width: 25px; left: 14px; top: 46px; transform: rotate(45deg); animation: 0.75s ease 0s 1 normal none running animateSuccessTip;
  }
  .success-r1-sp2 {
    height: 5px; background-color: rgb(92, 184, 92); display: block; border-radius: 2px; position: absolute; z-index: 2; width: 47px; right: 8px; top: 38px; transform: rotate(-45deg); animation: 0.75s ease 0s 1 normal none running animateSuccessLong;
  }

  .success-r2 {
    width: 80px; height: 80px; border: 4px solid rgba(92, 184, 92, 0.2); border-radius: 50%; box-sizing: content-box; position: absolute; left: -4px; top: -4px; z-index: 2;
  }
  .success-r3 {
    width: 5px; height: 90px; background-color: rgb(255, 255, 255); position: absolute; left: 28px; top: 8px; z-index: 1; transform: rotate(-45deg);
  }
  .success-r4 {
    border-radius: 0px 120px 120px 0px; position: absolute; width: 60px; height: 120px; background: white; transform: rotate(-45deg); top: -11px; left: 30px; transform-origin: 0px 60px; animation: 4.25s ease-in 0s 1 normal none running rotatePlaceholder;
  }
}

.icon-warning-h {
  width: 80px; height: 80px; border-width: 4px; border-style: solid; border-color: rgb(238, 162, 54); border-radius: 50%; margin: 20px auto; position: relative; box-sizing: content-box; animation: 0.75s ease 0s infinite alternate none running pulseWarning;
  .warning-sp1 {
    position: absolute; width: 5px; height: 47px; left: 50%; top: 10px; border-radius: 2px; margin-left: -2px; background-color: rgb(240, 173, 78); animation: 0.75s ease 0s infinite alternate none running pulseWarningIns;
  }
  .warning-sp2 {
    position: absolute; width: 7px; height: 7px; border-radius: 50%; margin-left: -3px; left: 50%; bottom: 10px; background-color: rgb(240, 173, 78); animation: 0.75s ease 0s infinite alternate none running pulseWarningIns;
  }
}

.icon-error-h {
  width: 80px; height: 80px; border-width: 4px; border-style: solid; border-color: rgb(212, 63, 58); border-radius: 50%; margin: 20px auto; position: relative; box-sizing: content-box; animation: 0.5s ease 0s 1 normal none running animateErrorIcon;

  .error-sp1 {
    position: relative; display: block; animation: 0.5s ease 0s 1 normal none running animateXMark;

    .error-sp1-s1 {
      position: absolute; height: 5px; width: 47px; background-color: rgb(217, 83, 79); display: block; top: 37px; border-radius: 2px; transform: rotate(45deg); left: 17px;
    }
    .error-sp1-s2 {
      position: absolute; height: 5px; width: 47px; background-color: rgb(217, 83, 79); display: block; top: 37px; border-radius: 2px; transform: rotate(-45deg); right: 16px;
    }
  }
}

.modal-buttons {
  display: flex; z-index: 1; flex-wrap: wrap; align-items: center; justify-content: center; width: 100%; margin: 1.25em auto 0px;
}
//

.modal-title {
  position: relative; max-width: 100%; margin: 0px 0px 10px; padding: 0px; font-size: 1.875em; font-weight: 600; text-align: center; text-transform: none; overflow-wrap: break-word;
  padding-bottom: 10px;
}
.modal-sub-title {
  z-index: 1; justify-content: center; margin: 0px; padding: 0px; font-size: 1.125em; font-weight: 300; line-height: normal; text-align: center; overflow-wrap: break-word;
  
}