.blurbg {
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(100, 100, 100, 0.5);
}

.wrapper {
  width: max-content;
  min-width: 30rem !important;
  text-align: left;
  padding: 0;
  margin: 0;
}
.sweet-alert {
  width:max-content !important;
  min-width: 22rem !important;
  min-height: 20rem !important;
}
.heading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #1D2226;
}

.button-row {
  display: flex;
  padding-bottom: 10px;
  flex-direction: row;
  justify-content: space-evenly;
}

.button {
  min-width: 120px;
  border-radius: 5px;
  padding: 0px 10px 0px 10px;
  border: 0;
  outline: 0;
  height: 25px;
}

.status-message {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}