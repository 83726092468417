@import "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700";
@import "https://cdnjs.cloudflare.com/ajax/libs/nvd3/1.8.1/nv.d3.min.css";
@import "https://unpkg.com/ionicons@4.2.2/dist/css/ionicons.min.css";

.form-control {
  font-weight: normal !important;
}

.rt-multiselect {
  .dropdown-container {
    height: 35px;
  }
}

.div-scroll {
  flex: 1;
  overflow-y: scroll;
}

.div-scroll-50 {
  height: 50vh;
}

.div-scroll-25 {
  height: 25vh;
}

.editor {
  display: inline-flex;
  gap: 10px;
  font-family: monospace;
  line-height: 21px;
  border-radius: 2px;
  padding: 20px 10px;
  border: 1px solid #d5dbe0;
  width: 100%;
}

.editor textarea {
  line-height: 21px;
  overflow-y: hidden;
  padding: 0;
  border: 0;
  outline: none;
  resize: none;
  width: 100%;
}

.line-numbers {
  width: 20px;
  text-align: right;
}

.line-numbers span {
  counter-increment: linenumber;
}

.line-numbers span::before {
  content: counter(linenumber);
  display: block;
  color: #506882;
}