@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#animate {
  -webkit-animation: fadein 0.3s ease-in;
  -moz-animation: fadein 0.3s ease-in;
  animation: fadein 0.3s ease-in;
}

.panel-button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.panel-button {
  min-width: 120px;
  border-radius: 5px;
  padding: 0px 10px 0px 10px;
  background: #00aeab;
  cursor: pointer;
  border: 0;
  outline: 0;
  color: white;
  height: 35px;
  font-size: 12px;
  margin: 10px 10px 20px 0px;
}
.panel-button:last-child {
  margin-right: 0;
}
.panel-button:hover {
  background: #01918f;
  transition: 0.3s;
}

.panel-button-reset {
  @extend .panel-button;
  transform: translateY(-4.5px);
}

.panel-iconbutton {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 90px;
  cursor: pointer;
  border-radius: 5px;
  padding: 0px 5px 0px 0px;
  background: #00aeab;
  border: 0;
  outline: 0;
  color: white;
  height: 35px;
  font-size: 12px;
  margin: 10px 10px 10px 0px;
}
.panel-iconbutton:last-child {
  margin-right: 0;
}
.panel-iconbutton:hover {
  background: #01918f;
  transition: 0.3s;
}
.panel-iconbutton-clear {
  @extend .panel-iconbutton;
  background: #747db8;
  gap: 2px;
}
.panel-iconbutton-clear:hover {
  background: #606899;
  transition: 0.3s;
}
.panel-iconbutton-search {
  @extend .panel-iconbutton;
  background: #91c918;
}
.panel-iconbutton-search:hover {
  background: #7baa14;
  transition: 0.3s;
}
.panel-iconbutton-close {
  @extend .panel-iconbutton;
  background: #727db5;
  gap: 2px;
}
.panel-iconbutton-close:hover {
  background: #606a99;
  transition: 0.3s;
}
.panel-iconbutton-save {
  @extend .panel-iconbutton;
  background: #2d8be3;
  gap: 2px;
}
.panel-iconbutton-save:hover {
  background: #1d61a1;
  transition: 0.3s;
}

.panel-heading {
  background: #1d2226;
  width: 100%;
  color: white;
}

.table-data {
  background: white;
  border: 1px solid lightgray;
  height: 40px;
}
.table-data-alter {
  background: #f3f5f4;
  border: 1px solid lightgray;
  height: 40px;
}
.table-data:hover,
.table-data-alter:hover {
  background: #ffffcd;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-button {
  border: 0;
  outline: 0;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  border-radius: 50%;
}
.form-button-search {
  @extend .form-button;
  background: #f29f1a;
}
.form-button-search:hover {
  background: #d48a15;
  transition: 0.3s;
}
.form-button-edit {
  @extend .form-button;
  background: #0ba9a9;
}
.form-button-edit:hover {
  background: #0a8a8a;
  transition: 0.3s;
}
.form-button-delete {
  @extend .form-button;
  background: #f63f3f;
}
.form-button-delete:hover {
  background: #d13434;
  transition: 0.3s;
}

.fade-appear {
  opacity: 0;
  z-index: 1;
}
.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 1000ms linear;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms linear;
}

.picker {
  min-width: 100%;
  input {
    min-width: 100%;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}
.go2646822163 {
  border: 1px solid #d5dbe0;
  height: 34px;
}
.dropdown-heading {
  height: 32.5px;
  width: 100%;
}
.backgroundProcessing {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: #a7a7a7;
  opacity: .4;

  .bpWaiting {
    position: relative;
    top: 50%;
    left: 50%;
    font-size: 30px;
    color: #000000;
    font-weight: bold;
  }
}