.tab-wrapper {
  width: auto;
  padding: 20px 15px 10px 15px;
  border-radius: 8px;
  height: max-content;
  margin: -15px -15px -15px -15px;
}

.tabs-row {
  width: calc(100% + 30px);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background: #C1CED0;
  transform: translate(-14.5px, -16px);
}

.tab {
  width: max-content;
  padding: 8px 15px 10px 15px;
  color: white;
  cursor: pointer;
  color: #1D2226;
  background: white;
  border: 0;
  outline: 0;
}

.inactive-tab {
  @extend .tab;
  color: #525151;
  background: #C1CED0;
}